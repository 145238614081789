$(document).ready(function () {
    // Menu overlay toggle
    $('.header__menu__btn').click(function () {
        const clicks = $(this).data('clicks');
        if (clicks) {
            $('body').removeClass('header__menu__open');
            $('.header__menu__text').html('MENU');
        }
        else {
            $('body').addClass('header__menu__open');
            $('.header__menu__text').html('SLUITEN');
        }
        $(this).data('clicks', !clicks);
    });

    if ($(window).width() < 1199) {
        $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>');
        $('.menu-item-touch').on('click', function () {
            $(this).parent('.menu-item').toggleClass('open');
        })
    }

    // Fixed Header
    function fixHeader() {
        let scroll = $(window).scrollTop();
        if (scroll > 50)
            $('.header').addClass('header--fix');
        else
            $('.header').removeClass('header--fix');
    }
    fixHeader();
    $(window).scroll(function () {
        fixHeader();
    });
});
