export const singleProjectenData = {
    init() {
        // JavaScript to be fired on all pages
        $('.slider__content').slick({
            dots: false,
            arrows: true,
            swipeToSlide: true,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
            asNavFor: '.slider__nav',
        });

        $('.slider__nav').slick({
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 3,
            focusOnSelect: true,
            asNavFor: '.slider__content',
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
