export default {
    init() {

        // Add class to faq accordion
        $('.card-header a').addClass('collapsed');

        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.header__search__toggle').on('click', function () {
            $('.header__search').toggleClass('open');
            $('.header__search__input input').focus();
        });

        // Onze Coating slider
        $('.onzecoating__slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: false,
            swipeToSlide: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 2500,
            slidesToShow: 5,
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
            responsive: [
                {
                    breakpoint: 1620,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        });

        // Projects slider
        $('.projects__slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: false,
            swipeToSlide: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 2500,
            slidesToShow: 4,
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        });

        // Layer Slider 1
        $('.layer__slider1').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: false,
            cssEase: 'linear',
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: 2500,
            slidesToShow: 3,
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
            responsive: [

                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        // Layer Slider 2
        $('.layer__slider2').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: false,
            swipeToSlide: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 2500,
            slidesToShow: 3,
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        // Left spacing adjusted to bewerkingen & materialen as per design
        function sectionLeftSpacing() {
            if ($('.onzecoating__wrapper').length) {
                if ($(window).width() > 575)
                    $('.onzecoating__wrapper').attr('style', 'padding-left:' + $('.container-fluid').offset().left + 'px;' + ';width:' + ($(window).width() + ($('.onzecoating__item').width() * 0.75)) + 'px');
                else
                    $('.onzecoating__wrapper').attr('style', 'padding-left:' + $('.container-fluid').offset().left + 'px;' + ';width:' + ($(window).width() + ($('.onzecoating__item').width() * 1)) + 'px');
            }

            if ($('.projects__wrapper').length) {
                if ($(window).width() > 575)
                    $('.projects__wrapper').attr('style', 'padding-left:' + $('.container-fluid').offset().left + 'px;' + ';width:' + ($(window).width() + ($('.projects__item').width() * 0.75)) + 'px');
                else
                    $('.projects__wrapper').attr('style', 'padding-left:' + $('.container-fluid').offset().left + 'px;' + ';width:' + ($(window).width() + ($('.projects__item').width() * 1)) + 'px');
            }
        }

        // Product filter toggle
        $('#project__toggle').on('click', function () {
            $('.project__filter').toggleClass('project__filter--open');
        });

        setTimeout(function(){
            sectionLeftSpacing();
        }, 200);

        $(window).resize(function () {
            sectionLeftSpacing();
        });

        if ($(window).width() <= 767) {
            $('.single__sidebar--faq .sidebar__title').on('click', function () {
                $('.single__sidebar--faq .sidebar__block').toggleClass('active');
            });
        }

        $('.project__filter__block .sf-input-select').addClass('project__toggle');

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
