$(document).ready(function() {
    $('.slider__content').slick({
        dots: false,
        arrows: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2500,
        prevArrow: $('.slider__top__prev'),
        nextArrow: $('.slider__top__next'),
        asNavFor: '.slider__nav',
    });

    $('.slider__nav').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        slidesToShow: 3,
        focusOnSelect: true,
        asNavFor: '.slider__content',
    });
});
