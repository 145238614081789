/* eslint-disable */
export const templateFaqData = {
    init() {

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

